<script>
export default {
    props: {
        stats: {},
        view: {}
    }
};
</script>

<template>
    <div class="row">
        <div class="col">
            <v-stats-card
                :title="$t('flagging.stat_flags_requiring_confirmation')"
                type="default"
                :value="stats.flags"
                icon="fal fa-flag"
                class="mark-clickable"
                @click.native="$router.push({ name: 'ProjectFlagsIndex' })"
                :dactive="view === 'flags'"
            />
        </div>
        <div class="col">
            <v-stats-card
                :title="$t('flagging.stat_milestone_status_change_req_conf')"
                type="default"
                :value="stats.statuses"
                icon="fal fa-thumbs-up"
                class="mark-clickable"
                @click.native="$router.push({ name: 'ProjectStatusMfa' })"
                :dactive="view === 'status'"
            />
        </div>
    </div>
</template>
